

#wrapper .sidebar{
  z-index: 1;
  background: #3B434E;
  position: fixed;
  top: 70px;
  width: 210px;
  height: calc(100% - 100px);
  transition: 0.3s;
  transition-property: width;
  overflow-y: auto;
}


#wrapper .sidebar .sidebar-menu{
  overflow: hidden;
  padding: 0px;
}

#wrapper .sidebar .sidebar-menu .item{
  width: 210px;
  overflow: hidden;
  line-height: 40px;
}

#wrapper .sidebar .sidebar-menu .item.active{
  background-color: #2F8079;
}

#wrapper .sidebar .sidebar-menu .item .menu-btn{
    align-items: center;
    color: #fff;
    position: relative;
    transition: 0.6s;
    white-space: nowrap;
    transition-property: color;
    display: flex;
    padding-left: 13px;
    border-left: 5px solid transparent!important;
}

#wrapper .sidebar .sidebar-menu .item.active .menu-btn{
    border-left: 5px solid #45BEA9 !important;
}

#wrapper .sidebar .sidebar-menu .item .menu-btn:hover{
  color: #45BEA9;
}

#wrapper .sidebar .sidebar-menu .item .menu-btn .icon{
  margin-right: 20px;
  color: #B1B4B8;
}

#wrapper .sidebar .sidebar-menu .item.active .menu-btn .icon{
  color: #fff;
}

#wrapper .sidebar .sidebar-menu .item .menu-btn .submenu_icon {
    position: absolute;
    right: 20px;
    color: #fff;
}

#wrapper .sidebar .sidebar-menu .item .sub_menu{
  background: #071223;
  overflow: hidden;
  max-height: 0;
  transition: 0.6s;
  transition-property: background, max-height;
}

#wrapper .sidebar .sidebar-menu .item .sub_menu a{
    display: block;
    position: relative;
    color: #fff;
    white-space: nowrap;
    padding: 0px 0px 0px 50px;
    transition: 0.6s;
    transition-property: background;
}
  
#wrapper .sidebar .sidebar-menu .item .sub_menu a.active {
  color: #45BEA9;
}
#wrapper .sidebar .sidebar-menu .item .sub_menu a:hover {
  background: #2F8079;
}

#wrapper .sidebar .sidebar-menu .item .sub_menu a:not(last-child){
  border-bottom: 0px solid #212529;
}

#wrapper .sidebar .sidebar-menu .item .sub_menu i{
  padding-right: 20px;
  font-size: 10px;
}

#wrapper .sidebar .sidebar-menu .item .sub_menu.open{
  max-height: 500px;
}

#wrapper .main-container{
    margin-left: 210px;
    width: calc(100% - 210px);
    margin-top: 70px;
    max-height: calc(100% - 70px);
    padding: 0;
    transition: 0.6s;
/*    position: fixed;*/
    /*overflow: auto;*/
    background-size: cover;
    position: relative;
}

#wrapper.min_sidebar .sidebar{
  width: 64px;
}

#wrapper.min_sidebar .sidebar .profile img,
#wrapper.min_sidebar .sidebar .profile p,
#wrapper.min_sidebar .sidebar a span{
  display: none;
}

#wrapper.min_sidebar .sidebar .sidebar-menu .item .menu-btn{
  /*font-size: 23px;*/
}

#wrapper.min_sidebar .main-container{
  width: calc(100% - 64px);
  margin-left: 64px;
}

.collapse_sidebar_btn {
    width: 210px;
    vertical-align: middle;
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    background-color: #071223;
    color: #fff;
    border: 0;
    padding: 11px 0px;
    transition: 0.3s;
    transition-property: width;
}

#wrapper.min_sidebar .collapse_sidebar_btn {
    width: 64px;
    transition: 0.3s;
    transition-property: width;
}