.nav_top {
    border-top: solid 3px #1d4881;
    padding: 10px 0px;
    font-size: 13px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-bottom: solid 1px rgba(225,225,225,.83)!important;
}


.social_icon {
    padding: 10px 20px 5px 20px;
    font-size: 18px;
    color: #545454;
}

.social_icon:hover,.social_icon:active {
    color: #545454;
}

.header_wrapper{
    border-bottom: 1px solid #e1e1e1!important;
    background-color: #ffffff;
    padding: 10px 0px;
    font-weight: normal;

}

.page_image {
    width: 100%;
    max-width: 100%;
    height: auto;
}


.login_box {
    -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
    min-height: 490px;
}


.login_links {
    color: #337ab7;
    font-size:13px !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.login_links:hover {
    color: #337ab7;
    text-decoration: none !important;
}

.tnc {
    /*line-height: 20px;*/
}