.filtered_search {
    position: relative;
}

.filtered_search ul {
    background-color: #dbeae8;
    font-size: 12px;
    line-height: 23px;
    padding: 10px 35px;
    margin-bottom: 5px;
    -webkit-box-shadow: 3px 7px 5px 0px rgba(194, 195, 197,1);
    -moz-box-shadow: 3px 7px 5px 0px rgba(194, 195, 197,1);
    box-shadow: 3px 7px 5px 0px rgb(194, 195, 197);
}

.filtered_search ul li:first-child {
    color: #ed6b75;
    font-style: italic;
    list-style: none;
    margin-left: -15px;
}

.filter_clear_link {
    font-style: italic;
    right: 20px;
    position: absolute;
    top: 5px;
}