/*Include Roboto Font*/
@import '../fonts/Roboto/index.css';
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";


body {
    color: #111213;
    background-color: #eee!important;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto';
    font-weight: 400;
    background: #D6DEDC 0% 0% no-repeat padding-box!important;
    overflow-y: hidden;
    /*overflow-x:hidden!important;*/
}

.font-roboto {
  font-family: 'Roboto';
  font-style: normal;
}
.font-roboto-italic {
  font-family: 'Roboto';
  font-style: italic;
}

.font-akaya-hand {
  font-family: 'Akaya Kanadaka';
  font-style: normal;
}

.app__header {
    z-index: 9;
    position: fixed;
    /*box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);*/
    width: calc(100% - 0%);
    top: 0;
    height: 70px;
    border-bottom: 1px solid #e9ecec;
}

.tap_border_left {
    border-left: 1px solid #e9ecec;

}


.dropdown-menu {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0px;
    opacity: 1;
    min-width: 156px;
    font-size: 14px;
    padding: 0px;
    z-index: 99999!important;
  /*  transform: translate(466px, 56px) !important;*/

}

.dropdown-item{
    padding: 12px 16px;
    letter-spacing: 0;
    color: #111213;
    opacity: 1;
    min-width: 156px;
    font-size: 14px;
     z-index: 99999!important;
}

/*Font Size*/
.fs11 {
    font-size: 11px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs13{
    font-size: 13px !important;
}
.fs14{
    font-size: 14px !important;
}

.fs24{
    font-size: 24px !important;
}

.fw500{
    font-weight: 500!important;
}
.text-sm {
    font-size: 90% !important;
}
.text-xs {
    font-size: 80% !important;
}
.text-l {
    font-size: 110% !important;
}
.text-xl {
    font-size: 120% !important;
}


.p0 { 
padding: 0 !important; 
}
.p0 { 
padding: 0 !important; 
}
.pl0 {
    padding-left: 0!important;
}
.pr0 {
    padding-right: 0 !important;
}
.pl1 {
    padding-left: 1px !important;
}
.pt9 {
    padding-to: 9px !important;
}
.pr1 {
    padding-right: 1px !important;
}
.pl2 {
    padding-left: 2px !important;
}
.pr4 {
    padding-right: 4px !important;
}
.pr5 {
    padding-right: 5px !important;
}
.pl4 {
    padding-left: 4px !important;
}
.p4{
    padding: 4px;
}
.pl5 {
    padding-left: 5px !important;
}
.pr2 {
    padding-right: 2px !important;
}
.pl10 {
    padding-left: 10px !important;
}
.pr10 {
    padding-right: 10px !important;
}
.pb0 {
    padding-bottom: 0!important;
}
.m0 {
    margin: 0 !important;
}
.ml0 {
    margin-left: 0!important;
}
.mr0 {
    margin-right: 0 !important;
}
.ml1 {
    margin-left: 1px !important;
}
.mr1 {
    margin-right: 1px !important;
}
.ml2 {
     margin-left: 2px !important;
 }
.mr2 {
    margin-right: 2px !important;
}
.ml3 {
    margin-left: 3px !important;
}
.ml5 {
    margin-left: 5px !important;
}
.ml4 {
    margin-left: 4px !important;
}
.ml6 {
    margin-left: 6px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml25 {
    margin-left: 25px !important;
}
.mr3 {
    margin-right: 3px !important;
}
.mr4 {
    margin-right: 4px !important;
}

.mt3 {
    margin-top: 30px !important;
}
.mt2 {
    margin-top: 20px !important;
}
.mt7 {
    margin-top: 7px !important;
}
.mt10 {
margin-top: 10px !important;
}
.mt13 {
    margin-top: 13px !important;
}
.mt12 {
    margin-top: 12px !important;
}
.mt15 {
margin-top: 15px !important;
}
.mt16 {
    margin-top: 16px !important;
}
.mt1 {
    margin-top: 10px !important;
}
.mb3 {
    margin-bottom: 30px !important;
}
.mb2 {
    margin-bottom: 20px !important;
}
.pr13 {
    padding-right: 13px !important;
}
.pl16 {
    padding-left: 16px !important;
}
.primary_color{
    color: #45BEA9;
}
.text-color {
    color: #000000!important; 
}
.legend_color_box{
    height: 18px;
    width: 25px;
    background-color: #4285f4;
}

.bg-green{
    background-color: #45BEA9!important;
}

.brred {
    border: solid 1px red;
}

input[type='checkbox'] {
    width :16px;
    height:16px
 }
input[type='checkbox']:after{
     background-color: #45BEA9!important;

 }
input[type='checkbox']:before{
    background-color: #45BEA9!important;

}




.custom {
    background-color: #fff;
}


.form-header {
    background-color: #1a467f;
    color: #fff;
    padding: 5px;
    margin: auto;
    text-align: center;
    font-size: 25px;
}

.form-control {
    height: 40px;
    border-radius: 0px;
    padding: 6px 12px;
    color: rgba(0, 0, 0, 1) !important;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 26px;
    border: 1px solid #d0d0d0;
    background: #FFF 0% 0% no-repeat padding-box;
}

textarea.form-control{
    height: auto;
}

.form-select {
    height: 40px;
    border-radius: 0px;
    padding: 6px 12px;
    color: rgba(0, 0, 0, 1) !important;
    font-size: 14px;
    line-height: 26px;
    border: 1px solid #d0d0d0;
}

.form-control-sm {
    height: 90%;
    font-size: 93%;
    border-radius: 0;
}
.col-form-label-sm { font-size: 95%}

.form-control-desc {
    padding: 6px 12px;
    color: rgba(0, 0, 0, 1) !important;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 16px;
    background: #F8F8F8 0% 0% no-repeat padding-box!important;
    border: 1px solid #979C9F;
    border-radius: 3px;
    opacity: 1!important
}

.alert-text{
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 600;
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
    color: #212529;
    border: 1px solid #aaaaaa7a;
}

.form-label {
    font-weight: 500;
}


.input-group-text {
    padding: 0px 19px;
    font-size: 20px;
    color: #212529;
    border: 1px solid #ececec;
    border-radius: 0px;
}

/** Button **/

.btn {
    border-radius: 2px;
    height: 32px;
    font-family: "Roboto";
    line-height: 17px;
    line-height: 0;
    min-width: 35px;
    font-size: 14px;
    padding: 8px 17px;
    border-radius: 2px;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
}


.btn.btn-link {
    padding: 8px 0px;
    color: #0052CC;
}

.btn.btn-link:hover {
    color: #0074d9;
}

.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 5%);
}

.btn-lg {
    height: 40px;
    padding: 12px 30px;
    font-size: 120%
}

.btn-light{
    border: 1px solid #e9ecef;
}


.btn-primary {
    color: #fff;
    background-color: #45BEA9;
    border-color: #45BEA9;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #45BEA9;
    border-color: #45BEA9;
}

.btn-secondary {
    color: #fff;
    background-color: #6E747C;
    border-color: #6E747C;
}

.btn-collapse{
    background-color: #2C7973!important;
    color: #fff;
    border-color :  #2C7973!important;
}
.btn-collapse:hover{
    background-color: #2C7973!important;
    color: #fff;
    border-color :  #2C7973!important;
}
.btn-primary:focus,.btn-primary:active,.btn-primary:hover {
    color: #fff;
    background-color: #3cb5a0;
    border-color: #3cb5a0;
    box-shadow: none;
}
.btn-secondary:focus,.btn-secondary:active,.btn-secondary:hover {
    color: #fff;
    background-color: #6E747C;
    border-color: #6E747C;
    box-shadow: none;
}

/*.btn-info {
    color: #fff;
    background-color: #1E457E;
    border-color: #1E457E;
}

.btn-primary {
    color: #fff;
    background-color: #1E457E;
    border-color: #1E457E;
}*/


a {
    color: #0052CC;
    text-decoration: none !important;
}
a:hover {
    color: #0074d9;
    text-decoration: none !important;
}




footer {
    background-color: #363839;
}

.footer_copywrite {

}

.goto_top {
    position: fixed;
    right: 10px;
    bottom: 4px;
    background-color: #4b4c4d;
    color: #fff;
    padding: 10px 20px;
    z-index: 99;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
}
.goto_top:hover{
    background-color: #3cb5a0
}

.alert {
    padding: 8px 16px;
    border-radius: 0;
    font-size: 13px;
}

.form-control::-webkit-input-placeholder {
  color: #aaa ;
}
.form-control::-moz-placeholder {
  color: #aaa;
}
.form-control:-ms-input-placeholder {
  color: #aaa;
}

.app-search__input, .app-search__input:focus {
    border: solid 1px #ececec;
    border-right: 0px;
}

.app-search__button {
    background  :   #F8F8F8 0% 0% no-repeat padding-box;
    border: solid 1px #ececec;
    border-left: 0px;
}

/* table css*/
thead {
    background: #C2CCD4 0 0 no-repeat padding-box;
    opacity: 1;
    height: 40px;
}
.table-head-row{
    border: 1px solid #A2ACB5;
    border-bottom: none!important;
}
.table-body td{
    letter-spacing: 0;
    color: #111213;
    opacity: 1;
    height: 40px;
    word-wrap: break-word
}

.table-fixed {
    overflow-y: auto;
    height: 50px;
}

.table-fixed thead{
    vertical-align: bottom;
    z-index: 1!important;
    position: sticky;
    top: -1px;
}

/*.table-fixed thead th {
    position: sticky;
    top: -1px;
    z-index: 1!important;
  }*/
  
/*Modal Styles*/
.action-form-title{
    font-size: 16px;
    font-family: "Roboto";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 700;
    padding: 10px 8px;
}
.action-btn{
    border-radius: 2px;
    /*height: 32px;*/
    font-family: "Roboto";
    /*line-height: 17px;*/
    line-height: 0;
    min-width: 35px;
    font-size: 14px;
    border: none;
    background-color: white;
    padding: 2px 17px;
    border-radius: 2px;
}

.modal_add_button{
    margin-left:10px;
    width:110px;
}

/*Form styling*/
.form-section{
    padding: 1.3rem;
    border-left: 1px solid #C2CCD4!important;
}

.form-section .form-label {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: black !important;
    opacity: 1 !important;
    font-weight: 500;
    line-height: 30px;
}

.form-section .form-control {
    border: 1px solid #979C9F;
    border-radius: 3px;
    opacity: 1;
}

input[type="text"]:disabled {
    opacity: 0.7!important;
    background-color: #e9ecef !important;
}

.fixed_footer {
    position: sticky;
    bottom: 5px;
    /*z-index: 100;*/
    background-color: #fff;
    padding: 15px 0;
    border-top: 1px solid hsl(0, 0%, 80%);
}

.badge{
    padding: 6px 6px;
    font-size: 12px;
    border-radius: 2px;
    margin-right: 5px;

/*    height: 24px!important;
    width: 100%!important;
    border-radius: 2px!important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;*/
}

.badge-secondary{
    background: #3B434E!important;
    border: 1px solid #3B434E!important;
    box-sizing: border-box!important;
}
.badge_group{
    display: flex;
    flex-direction: row;

}

.page_title {
    height: 60px;
    background-color: #fff;
    /*position: fixed;*/
    /*top: 70px;*/
    /*width: 100%;*/
    z-index: 10;
}

.page_title h3 {
    font-size: 18px;
    padding: 20px 0px 0 5px;
    float: left;
    display: inline-block;
}

.page_title .btn {
    margin-left: 5px;
    /*padding-top: 8px;*/
}
.page_containt {
    height: 79vh;
    top:4px;
    position: relative;
    padding-right: 0px;
}

.pageTbl {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 103%;
    padding-right: 0!important;
}

.table-secondary {
    background-color: #C2CCD4 !important;

}

.table-sm td , .table-sm th{
    vertical-align: middle ;
    /*font-size: 13px;*/
}








.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: green;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.require::after {
    content: ' *';
    color: red;
}


.item_view_container{
    padding: 0px;
    border-left:1px solid hsl(0, 0%, 80%) ;
    position: relative;

}
.tab-header  {
    list-style: none;
    background: #fff;
    height: 40px;
    border-bottom: 1px solid hsl(0, 0%, 80%);

}
.tab-header ul{
    overflow: auto!important;
}
.tab-header li{
    display: inline-block!important;
    padding: 12px 21px 12px 21px!important;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    cursor: pointer;
    color: #000000;
}

.tab-header li.active,
.tab-header li:hover {
    border-bottom: 4px solid #45BEA9; /* fallback for old browsers */

}
.item_view_container ul {
    padding: 0 !important;
    margin: 0 !important;
}

.tab_content_header{
    background-color: #FFFFFF;
    padding: 16px;
    height: 75vh;
    overflow-y: auto;
}

.tab_content_wrapper{
    border-left: 4px solid #45BEA9;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center !important;
}

.content_heading{
    font-weight: 700;
}

.edit_content_button {
    width: 32px;
    height: 32px;
    background-color: #45BEA9;
    margin: 0 8px;
    text-align: center;
    line-height: 28px;
    border-radius: 2px;
}

.item_view_container tr td{
    /*padding: 12px;*/
}
.details-label{
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

}
.details-name{
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

@media only screen and (min-width: 650px) {
    .tab_content_wrapper{
        display: flex;
        justify-content: space-between;
    }
}

.react-datepicker-wrapper { 
display : block;
/*width: 100%*/
}

.react-datepicker-wrapper input {
    padding: 7px 12px 8px 12px !important;
}

.asset_card_details{
    padding: 11px 17px!important;
}

.add_calender_section{
    position: relative;
}

.add_calender-icon{
    position: absolute;
    top: 10px;
    right: 20px;
    opacity:0.6
}

.text-helping {
    padding: 5px!important;
    font-size: 12px!important;
    color: #212529;
    border: 1px solid #ececec;
    border-radius: 0px;
    text-align: center;
    display: block;
}


/*css for input number text*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.subtotal_bg_color{
    background-color:  #F4F9F8!important;
}
.total_bg_color{
    background-color: #DEEEEA!important;
}

/*Css for new tabs*/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black!important;
    background-color: #fff;
    border-bottom: 4px solid #45BEA9!important;
    /* border-color: #dee2e6 #dee2e6 #fff; */
}

.nav-tabs .nav-link {
   border: none!important;
}
.nav-link {
    display: block;
    padding: .6rem 1.1rem!important;
    font-size:14px;
    font-family : "Roboto";
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

/*cs for feedback form*/

.rating {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 49px;
    font-size: 44px;
}

.rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
}

.rating label:last-child {
    position: static;
}

.rating label:nth-child(1) {
    z-index: 5;
}

.rating label:nth-child(2) {
    z-index: 4;
}

.rating label:nth-child(3) {
    z-index: 3;
}

.rating label:nth-child(4) {
    z-index: 2;
}

.rating label:nth-child(5) {
    z-index: 1;
}

.rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.rating label .icon {
    float: left;
    color: transparent;
}

.rating label:last-child .icon {
    color: #000;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
    color: #45BEA9;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
    color: #000;
    text-shadow: 0 0 5px #45BEA9;
}

.modal-content {
    -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.73);
    -moz-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.73);
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.73);
    border-radius: 0px;
}

h5.modal-title {
   font-size: 16px;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 9px 15px;
    background-color: #efeded;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}


/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px #ccc;*/ 
  border-radius: 7px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3CB5A0; 
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6fc8b9; 
}

.form-check-label {
    margin-top: .25em;
}

.modal-card-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.react-datepicker-popper {
    z-index: 3;
}

.tab_active{
    border-bottom: 4px solid #45BEA9;
}

.nav-tabs > .active > a, .nav-tabs > .active > a:hover {
    outline: none!important;
}

.disabled-link {
  pointer-events: none!important;
  cursor: pointer!important;
}

.cursor_pointer{
    cursor: pointer;
}

.rightSelectText .css-1wh03ml-singleValue{
    right : 0px!important
}
.center{
    height: 73%;
  width: 84%;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
}


.asset_collapse {
    position: relative;
}
.asset_collapse .asset_collapse_button{
    position: absolute;
    left : -5px;
    top:9px;
}

.asset_collapse  .asset_table{
    position: absolute;
    top:0px;
    width: 30%;
    z-index: 999;
    border: 1px solid #e9ecec;
}

.hide {
    display: none;
  }
      
  .hide-button:hover .hide {
    display: block;
  }


  .counterlog{
    display: block;
    border: 1px dashed #666a6d;
    padding: 20px 10px;
    background-color: whitesmoke;
    margin-bottom: 20px
  }

  .card-image{
    max-height: 10em!important;
    max-width: 100%!important;
}

.asset_matrix_card_image{
    display: block;
    width: 100%;
    max-height: 14rem!important;
}

#executeCounterlogModal .react-datepicker {
    display: inline-flex!important;
 }

.wd26{
 width:  26%!important;
}

.wd74{
    width:  74%!important; 
}

.tooltip-element {
    position: relative;
    cursor: pointer;
    /* display: inline-block;  */
    /* border-bottom: 1px dotted black; */
  }
  
  .tooltip-element .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    top: -15px;
     left: 105%; 
     width: 160px; 
   
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip-element:hover .tooltiptext {
    visibility: visible;
  }

 #invoiceRuleForm .form-check-input:checked{
    background-color: #45BEA9!important;
    border-color: #45BEA9!important;
 }

 ol.horizontal{
    list-style-type: decimal;
    width: 600px;
}

ol.horizontal li{
    float: left;
    width: 180px;
    padding: 2px 0px;
}

.align-bottom {
  vertical-align:  bottom !important;
}

.react-datepicker__time-list-item--disabled{
    display: none!important
}

.error_border  {
   border : 1px solid #dc3545!important;
}

.nav_top {
    border-top: solid 3px #1d4881;
    padding: 10px 0px;
    font-size: 13px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-bottom: solid 1px rgba(225,225,225,.83)!important;
}


.social_icon {
    padding: 10px 20px 5px 20px;
    font-size: 18px;
    color: #545454;
}

.social_icon:hover,.social_icon:active {
    color: #545454;
}

.header_wrapper{
    border-bottom: 1px solid #e1e1e1!important;
    background-color: #ffffff;
    padding: 10px 0px;
    font-weight: normal;

}


.login_links {
    color: #337ab7;
    font-size:13px !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.login_links:hover {
    color: #337ab7;
    text-decoration: none !important;
}

.bg_white   {
    background-color: #ffffff;
}

.ck-editor__editable
 {
    min-height: 150px !important;
    max-height: 400px !important;
 }

 .resource_table ul {
    list-style-position: inside!important;
 }

 .ck-editor__editable {
    height: 400px;
}

.iot .ck-editor__editable {
    height: 250px;
}