.container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #fff !important;
}

.wrap {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}