
.app-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
    z-index: 1030;
    padding: 8px 20px;
}

.app-header_logo {
    width: 250px;
}
.rotate{
    transform: rotate(45deg)!important;
}
 
/*
.select-selected {
    padding: 12px 16px;
    border: 1px solid transparent;
    background: #F8F8F8 0% 0% no-repeat padding-box!important;
    cursor: pointer;
    user-select: none;
    text-align: left;
    font-family: "Roboto Regular",sans-serif;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    font-weight: 500;
    color: rgba(0, 0, 0, 1) !important;
    opacity: 1;
}*/
