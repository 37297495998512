.tab_content_header{
    background-color: #FFFFFF;
    padding: 16px;
    height: 83vh;
    overflow-y: auto;
    /*margin-top: 4px;*/
}

.card_border{
    border-bottom: 2px solid #9eacb4 !important;

}
.card_body{
    padding:15px 15px;
    position: relative;
}
.card_icon{
    position: absolute;
    top:10px;
    right: 0;

}

